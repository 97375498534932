import Head from "next/head";
import React from "react";
import { capitalizeFirstLetter, toAppUrl } from "../assets/js/utils/functions";
import { StoreInterface } from "../assets/interfaces";
export type PageTags = {
  title: string;
  description: string;
  pageUrl: string;
  image: string;
};

interface Props {
  tags: PageTags;
  store?: StoreInterface;
}

const SEOTags: React.FC<Props> = ({ tags, store }) => {
  const pageUrl = toAppUrl(tags.pageUrl);
  const faviconUrl = store?.logo || "/favicon.png";

  return (
    <Head>
      <link rel="icon" href={faviconUrl} type="image/png" />
      <title>{capitalizeFirstLetter(tags.title)}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={tags.description} />
      <meta property="og:title" content={capitalizeFirstLetter(tags.title)} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:description" content={tags.description} />
      <meta name="twitter:site" content="@myshopcatlog"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:title" content={capitalizeFirstLetter(tags.title)} />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:description" content={tags.description} />
      <meta property="og:image" content={tags.image} />
      <meta name="twitter:image" content={tags.image} />
    </Head>
  );
};

export default SEOTags;
